// Get stored theme mode
let themeMode = localStorage.getItem("themeMode");

// IF THERE ISN'T STORED THEME VALUE, USE DEVICE MODE
if (themeMode == null) {
  // Get device theme mode
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  themeMode = isDarkMode ? "dark" : "light";
}

// SET DARK MODE IF NEED BE
if (themeMode == "dark") {
  const rootElement = document.body;
  rootElement.classList.add('darkMode');
} else {
  var checkbox = document.getElementById('toggle') as HTMLInputElement;

  // Check the checkbox
  checkbox.checked = true;
}

// LISTEN TOGGLE TO SWITCH MODE
const toggle = document.querySelector("#toggle");
if (toggle != null) {
  toggle.addEventListener("click", () => {
    const rootElement = document.body;
    if (themeMode == "dark") {
      themeMode = "light";
    } else {
      themeMode = "dark";
    }
    localStorage.setItem("themeMode", themeMode);
    rootElement.classList.toggle("darkMode");
    //

  });
}

// TYPE WRITER EFFECT
document.addEventListener('DOMContentLoaded', () => {
  function typewriterEffect(
    elementId: string,
    newWords: string[],
    typingDelay: number = 120,
    deletionDelay: number = 60,
    pauseDelay: number = 1500
  ): void {
    const element = document.getElementById(elementId);
    if (!element) return;

    let wordIndex = -1;
    let charIndex = element.textContent?.length || 0;
    let isDeleting = true;
    let currentText = element.textContent || '';

    const updateText = (text: string) => {
      element.textContent = text;
    };

    const type = () => {
      if (isDeleting) {
        if (charIndex > 0) {
          // Start deleting characters
          charIndex--;
          currentText = currentText.substring(0, charIndex);
          updateText(currentText);
          setTimeout(type, deletionDelay);
        } else {
          // Once all characters are deleted, switch to the next word and start adding characters
          isDeleting = false;
          wordIndex = (wordIndex + 1) % newWords.length;
          setTimeout(type, typingDelay);
        }
      } else {
        if (charIndex < newWords[wordIndex].length) {
          // Add characters until the whole word is completed
          currentText = newWords[wordIndex].substring(0, charIndex + 1);
          charIndex++;
          updateText(currentText);
          setTimeout(type, typingDelay);
        } else {
          // Once the whole word is displayed, start the deleting process after a pause
          isDeleting = true;
          setTimeout(type, pauseDelay);
        }
      }
    };

    type();
  }

  setTimeout(() => {
    typewriterEffect('typewriter-text', ['beautiful mobile application;', 'complex web application;', 'AI integration;']);
  }, 2000);
});

// BACK TO TOP
document.addEventListener('DOMContentLoaded', () => {
  // Get the button element
  const backToTopButton = document.getElementById('back_to_top');

  // Listen for click events on the button
  if (backToTopButton) {
    backToTopButton.addEventListener('click', () => {
      const mainContainer = document.getElementById('main-content')!;
      // Animate the scroll to top
      mainContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }
});

// TOP MENU
var ignoreHideMenu = false;
function hideMenu() {
  const menu = document.getElementById('menu_bar'); // replace with your actual menu element ID or selector
  menu!.style.transition = 'transform 0.3s ease-in-out';
  menu!.style.transform = 'translateY(-100%)'; // hides the menu
}
function showMenu() {
  const menu = document.getElementById('menu_bar'); // replace with your actual menu element ID or selector
  menu!.style.transition = 'transform 0.3s ease-in-out';
  menu!.style.transform = 'translateY(0)'; // shows the menu
}
document.addEventListener('DOMContentLoaded', () => {
  const mainContainer = document.getElementById('main-content')!;
  //let lastScrollTop = document.documentElement.scrollTop;

  let lastScrollTop: number = 0;
  mainContainer.addEventListener('scroll', function () {
    let currentScroll = mainContainer.scrollTop;

    if (ignoreHideMenu) {
      return;
    }

    // Check if scrolled down more than 20 pixels
    if (currentScroll > lastScrollTop && currentScroll - lastScrollTop > 50) {
      // Scroll Down
      hideMenu();
    } else if (currentScroll < lastScrollTop) {
      // Scroll Up
      showMenu();
    }

    lastScrollTop = currentScroll; // Update lastScrollTop to the current scroll position
  }, false);
});

// RESIZE TEXT TO FIT CONTAINER
document.addEventListener('DOMContentLoaded', () => {
  const resizeTextElements = (className: string): void => {
    const elements = document.getElementsByClassName(className);
    Array.from(elements).forEach((element) => {
      if (!(element instanceof HTMLElement)) return;

      let fontSize: number = parseFloat(window.getComputedStyle(element, null).getPropertyValue('font-size'));

      const adjustFontSize = () => {
        element.style.fontSize = ''; // Reset font size to default to get the initial value
        fontSize = parseFloat(window.getComputedStyle(element, null).getPropertyValue('font-size'));

        // Decrease the font size until the text fits within the element's width
        while (element.scrollWidth > element.offsetWidth && fontSize > 0) {
          fontSize -= 1;
          element.style.fontSize = `${fontSize}px`;
        }
      };

      // Initial adjustment
      adjustFontSize();

      // Adjust on window resize
      window.addEventListener('resize', adjustFontSize);
    });
  };

  // Call the function with the class of your elements
  resizeTextElements('auto-resize-text');
});

// SELECTED MENU ANIMATION
var selectedAnimantionTimer: number | null = null;
document.addEventListener('DOMContentLoaded', () => {
  const menuEntries = document.querySelectorAll('#right_menu span');
  const highlight = document.getElementById('background-highlight')!;

  menuEntries.forEach(entry => {
    const link = entry.querySelector('.smooth-scroll')!;
    if (!link) return;
    link.addEventListener('click', event => {
      // Prevent the default anchor behavior
      event.preventDefault();

      // Get the target element ID from the href attribute
      const targetId = link.getAttribute('href')!.substring(1);
      const targetElement = document.getElementById(targetId);

      // Remove 'selected' class from all menu entries
      menuEntries.forEach(entry => {
        entry.classList.remove('selected');
      });

      // Add 'selected' class to the parent span of the clicked link
      if (link.parentElement) {
        link.parentElement.classList.add('selected');
      }
      updateHighlightPosition(link);

      // Scroll the target element into view smoothly
      if (targetElement) {
        ignoreHideMenu = true;

        targetElement.scrollIntoView({
          behavior: 'smooth'
        });
        if (selectedAnimantionTimer !== null) {
          window.clearTimeout(selectedAnimantionTimer);
        }
        selectedAnimantionTimer = window.setTimeout(() => {
          ignoreHideMenu = false;
        }, 2000);

      }
    });
  });

  function updateHighlightPosition(selectedLink: Element | null) {
    const menu = document.getElementById('right_menu')!; // replace with your actual menu element ID or selector
    if (selectedLink) {
      const linkRect = selectedLink.parentElement!.getBoundingClientRect();
      const menuRect = menu.getBoundingClientRect();

      highlight.style.width = `${linkRect.width}px`;
      highlight.style.height = `${linkRect.height}px`;
      highlight.style.transform = `translateX(${linkRect.left - menuRect.left}px)`;
      highlight.style.transition = 'transform 0.3s ease, width 0.3s ease';
    }
  }

  // Initialize the highlight position on the first selected item
  const selectedLink = document.querySelector('#right_menu .selected .smooth-scroll');
  updateHighlightPosition(selectedLink);

  function onScroll() {
    if (ignoreHideMenu) return;

    let currentSection: Element | null = null;

    menuEntries.forEach((entry: Element) => {
      const link = entry.querySelector('.smooth-scroll');
      const targetId = link ? link.getAttribute('href')!.substring(1) : null;
      const targetElement = targetId ? document.getElementById(targetId) : null;

      if (targetElement) {
        const targetRect = targetElement.getBoundingClientRect();
        const targetInView = targetRect.top < window.innerHeight && targetRect.bottom > 0;

        if (targetInView) {
          currentSection = entry;
        }
      }
    });

    // Update the 'selected' class and highlight position if a section is in view
    if (currentSection) {
      menuEntries.forEach((entry: Element) => {
        entry.classList.remove('selected');
      });
      var e: Element = currentSection;
      e.classList.add('selected');
      updateHighlightPosition(e.querySelector('.smooth-scroll'));
    }
  }

  // Listen to scroll events
  const mainContainer = document.getElementById('main-content')!;
  mainContainer.addEventListener('scroll', onScroll);

});

// MOBILE EXPANDABLE ABOUT
document.addEventListener('DOMContentLoaded', (_event: Event): void => {
  const moreButton = document.querySelector('.more-button') as HTMLSpanElement | null;
  const expandableContent = document.querySelector('.expandable-content') as HTMLDivElement | null;

  if (moreButton && expandableContent) {
    moreButton.addEventListener('click', function (): void {
      if (!expandableContent.classList.contains('expanded')) {
        // Expand content
        expandableContent.style.height = expandableContent.scrollHeight + 'px';
        expandableContent.classList.add('expanded');
        moreButton.textContent = 'less...';
      } else {
        // Collapse content
        // Set height to current scrollHeight before transitioning to 0
        expandableContent.style.height = expandableContent.scrollHeight + 'px';

        // Force a reflow, so the browser recognizes the starting height
        void expandableContent.offsetHeight;

        // Transition the height to 0
        expandableContent.style.height = '0';
        expandableContent.classList.remove('expanded');
        moreButton.textContent = 'more...';
      }
    });

    expandableContent.addEventListener('transitionend', () => {
      // Remove the inline height style after expansion transition is complete
      if (expandableContent.classList.contains('expanded')) {
        // expandableContent.style.height = '';
      }
    });
  }
});

// FLIP CARD
document.addEventListener('DOMContentLoaded', (_event: Event): void => {
  // Select all elements with the 'flip-card' class
  const flipCards = document.querySelectorAll('.flip-card');

  // Iterate over the NodeList and add a click event listener to each card
  flipCards.forEach(function (card) {
    card.addEventListener('click', function () {
      if (window.innerWidth < 640) {
        return;
      }
      if (this.flipping) {
        return;
      }
      this.classList.remove('idle');
      this.classList.toggle('is-flipped');
      this.ignoreOver = true;
      this.flipping = true;
      if (!this.classList.contains('is-flipped')) {
        setTimeout(() => {

          this.classList.add('idle');
          this.ignoreOver = false;
        }, 800);
      }
      setTimeout(() => {
        this.flipping = false;
      }, 1000);

    });
  });
});

// WIGGLE CARD
document.addEventListener('DOMContentLoaded', (_event: Event): void => {
  // Select all elements with the 'flip-card' class
  const flipCards = document.querySelectorAll('.flip-card');

  // Iterate over the NodeList and add a click event listener to each card
  flipCards.forEach(function (card) {
    card.addEventListener('mouseenter', function () {
      if (!this.ignoreOver) {
        this.classList.add('over');
      }
    });
    card.addEventListener('mouseleave', function () {
      if (!this.ignoreOver) {
        this.classList.remove('over');
      }
    });
  });
});


// MOBILE WORK DETAIL  PAGE
window.addEventListener('popstate', function (event) {
  if (event.state == null || event.state.page != "#home") {
    navigateTo("#home")
  }
});

// BACK HOME BUTTON
document.getElementById("back_to_home")?.addEventListener('click', function (e: Event) {
  navigateTo("#home");
});

// MOBILE NAVIGATION (PUSH STATE)
document.querySelectorAll('.link').forEach((link: Element) => {
  link.addEventListener('click', function (e: Event) {
    e.preventDefault();
    if (window.innerWidth > 640) {
      return;
    }
    e.stopPropagation();
    // Get content

    // 'this' is inferred as Element type within event listener
    let targetId = this.getAttribute('href');
    if (targetId == null) {
      targetId = '#' + this.getAttribute('id') + "_details";
    }
    var content = this.parentElement.getElementsByClassName('flip-card-back')[0].innerHTML
    var target = document.getElementById(targetId.substring(1));
    if (target?.innerHTML.trim() == "" && content != null) {
      target?.insertAdjacentHTML('beforeend', content);
    }
    console.log('content: ' + content);


    if (targetId) {
      navigateTo(targetId);
    }
  });
});

// MOBILE NAVIGATION (PUSH STATE)
function navigateTo(targetId) {
  const targetPage: HTMLElement | null = document.querySelector(targetId);
  const activePage: HTMLElement | null = document.querySelector('.page.active');
  const outPage: HTMLElement | null = document.querySelector('.page.out');

  if (targetPage == activePage) {
    document.getElementById('back_to_top')?.click();
    return;
  }

  const pages = document.querySelectorAll('.page');

  // Iterate over the NodeList and add a click event listener to each card
  Array.from(pages).forEach((page) => {
    if (!(page instanceof HTMLElement)) return;
    if (page.id != "home") {
      page.classList.add("hidden");
    }
  });

  if (activePage) {
    activePage.classList.remove('active');
    if (outPage) {
      outPage.classList.remove('out');
    } else {
      showMenu();
      activePage.classList.add('out');
    }
  }

  if (targetPage) {
    targetPage.classList.remove('hidden');
    setTimeout(() => {
      var backHome = document.getElementById('back_to_home');
      if (targetPage.id != "home") {
        history.pushState({ page: targetId }, '', targetId);
        backHome?.classList.add('visible');
        //        hideMenu();
      } else {
        backHome?.classList.remove('visible');
        history.replaceState({ page: '' }, '', '/');
      }
      targetPage.classList.add('active');
    }, 10);
  }

}
